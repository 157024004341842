import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { API_URL } from '../config/app.config';
import { joinUrl } from '../util/join-url';
import { DashboardInformation } from './dashboard-information';

export const DASHBOARD_INFORMATION_ID = 'main-page';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DashboardInformationService {
  private readonly dashboardUrl = 'backend/dashboard';

  constructor(
    @Inject(API_URL) private readonly apiUrl: string,
    private readonly http: HttpClient,
  ) {}

  public retrieveAll(): Observable<DashboardInformation> {
    return this.http
      .get<DashboardInformation[]>(joinUrl(this.apiUrl, this.dashboardUrl, 'all'))
      .pipe(
        untilDestroyed(this),
        switchMap((dashboardInformations: DashboardInformation[]) => {
          return of(this.findDashboardInFormation(dashboardInformations));
        }),
      );
  }

  public save(dashboardInformation: DashboardInformation) {
    return this.http.post(joinUrl(this.apiUrl, this.dashboardUrl), dashboardInformation, {
      responseType: 'text',
    });
  }

  private findDashboardInFormation(
    dashboardInformations: DashboardInformation[],
  ): DashboardInformation {
    return (
      dashboardInformations.find(
        (dashboardInformation: DashboardInformation) =>
          dashboardInformation.id === DASHBOARD_INFORMATION_ID,
      ) ||
      new DashboardInformation(
        '_',
        'Dit dashboard toont alle indicatoren van het indicatoren programma bereikbaarheid.',
      )
    );
  }
}
